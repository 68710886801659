export default {
  state: {
    attributes: [],
    modifiedAttributes: [],
  },
  getters: {
    iotGetAttributes: (state) => state.attributes,
    iotGetModifiedAttributes: (state) => (pageName) => state.modifiedAttributes.filter(ma => ma.pageName === pageName),
    iotGetModifiedAttribute: (state) => (search) => state.modifiedAttributes.find(ma => ma.deviceUuid === search.deviceUuid && ma.functionUuid === search.functionUuid && ma.attributeUuid === search.attributeUuid && ma.pageName === search.pageName),
    iotGetModifiedAttributesWithError: (state) => (pageName) => state.modifiedAttributes.filter((ma) => ma?.errorMessage?.length > 0 && ma.pageName === pageName),
  },
  mutations: {
    SET_ATTRIBUTES: function (state, value) {
      state.attributes = value;
    },
    SET_MODIFIED_ATTRIBUTES: function (state, value) {
      state.modifiedAttributes = value;
    },
  },
  actions: {
    iotSetAttributes: function (store, payload) {
      store.commit('SET_ATTRIBUTES', payload);
    },
    iotAddModifiedAttribute: function (store, payload) {
      const { modifiedAttributes } = store.state;
      const { commit } = store;

      const modifiedAttributesDeepCopy = JSON.parse(JSON.stringify(modifiedAttributes));

      const foundIndex = modifiedAttributes.findIndex(
        (ma) => ma.attributeUuid === payload.attributeUuid
        && ma.functionUuid === payload.functionUuid
        && ma.deviceUuid === payload.deviceUuid
        && ma.pageName === payload.pageName,
      );

      if (foundIndex === -1) {
        commit('SET_MODIFIED_ATTRIBUTES', modifiedAttributesDeepCopy.concat(payload));
        return;
      }

      const modifiedAttributeInStore = modifiedAttributes[foundIndex];

      if (
        modifiedAttributeInStore
        && modifiedAttributeInStore.attributeValueDto !== payload.attributeValueDto
      ) {
        commit(
          'SET_MODIFIED_ATTRIBUTES',
          modifiedAttributesDeepCopy.toSpliced(foundIndex, 1, payload),
        );
      }
    },
    iotRemoveModifiedAttribute: function (store, payload) {
      const { modifiedAttributes } = store.state;
      const { commit } = store;

      const modifiedAttributesDeepCopy = JSON.parse(JSON.stringify(modifiedAttributes));

      const foundIndex = modifiedAttributes.findIndex(
        (ma) => ma.attributeUuid === payload.attributeUuid
          && ma.functionUuid === payload.functionUuid
          && ma.deviceUuid === payload.deviceUuid
          && ma.pageName === payload.pageName,
      );

      if (foundIndex === -1) {
        return;
      }

      commit('SET_MODIFIED_ATTRIBUTES', modifiedAttributesDeepCopy.toSpliced(foundIndex, 1));
    },
    iotAddErrorToModifiedAttribute: function (store, payload) {
      const { modifiedAttributes } = store.state;
      const { commit } = store;

      const modifiedAttributesDeepCopy = JSON.parse(JSON.stringify(modifiedAttributes));

      const foundIndex = modifiedAttributes.findIndex(
        (ma) => ma.attributeUuid === payload.attributeUuid
          && ma.functionUuid === payload.functionUuid
          && ma.deviceUuid === payload.deviceUuid
          && ma.pageName === payload.pageName,
      );

      const modifiedAttributeWithError = modifiedAttributesDeepCopy[foundIndex];
      modifiedAttributeWithError.errorMessage = payload.errorMessage;

      commit(
        'SET_MODIFIED_ATTRIBUTES',
        modifiedAttributesDeepCopy.toSpliced(foundIndex, 1, modifiedAttributeWithError),
      );
    },
    iotClearModifiedAttributes: function ({ commit, state }, payload) {
      const pageName = payload;
      const modifiedAttributesDeepCopy = JSON.parse(JSON.stringify(state.modifiedAttributes));

      commit('SET_MODIFIED_ATTRIBUTES', modifiedAttributesDeepCopy.filter(ma => ma.pageName !== pageName));
    },
  },
};
