import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $select, $autocomplete, $util, } = Vue.prototype;
function getTicketFields() {
    return {
        worksitesId: new Field({
            name: 'IDS_PARQUE_SERVICO',
            component: 'ASelect',
            props: {
                label: i18n.t('WORKSITE'),
                entity: $select.worksites,
                clearable: true,
                multiple: true,
            },
            dependsOn: [
                {
                    fieldName: 'IDS_PARQUE_SERVICO',
                    handler: function () {
                        if (!this.fields.worksitesId.value) {
                            this.fields.ticketTypeId.value = null;
                        }
                    },
                },
            ],
        }),
        worksiteId: new Field({
            name: 'ID_PARQUE_SERVICO',
        }),
        search: new Field({
            name: 'BUSCA',
            component: 'ATextField',
            parser: (value) => value || '',
            props: {
                label: i18n.t('SEARCH'),
                clearable: true,
            },
        }),
        complainDate: new Field({
            name: 'DATA_RECLAMACAO',
            component: 'ADatePicker',
            parser: $util.relativeDateParser,
            props: {
                label: i18n.t('INITIAL_REQUEST_DATE'),
                'text-field': true,
                'return-serve': true,
                'enable-relative-picker': true,
            },
        }),
        finalDate: new Field({
            name: 'DATA_FINAL',
            component: 'ADatePicker',
            parser: $util.relativeDateParser,
            props: {
                label: i18n.t('FINAL_REQUEST_DATE'),
                'text-field': true,
                'return-serve': true,
                'enable-relative-picker': true,
            },
        }),
        protocolSearch: new Field({
            name: 'NUM_PROTOCOLO',
            component: () => import('@components/protocolCombobox/ProtocolCombobox.vue'),
            parser: (value) => (value != null && value.length !== 0 ? value.join(',') : ''),
            props: {
                label: i18n.t('PROTOCOL'),
            },
            dependsOn: [
                {
                    fieldName: 'IDS_PARQUE_SERVICO',
                    handler: (fields) => {
                        fields.protocolSearch.props.disabled = !fields.worksitesId.value && !fields.worksiteId.value;
                    },
                },
            ],
        }),
        onlyOpen: new Field({
            name: 'APENAS_EM_ABERTO',
            component: 'ASwitch',
            props: {
                label: i18n.t('ONLY_ACTIVE_EXCEPT_IN_ELABORATION'),
                'true-value': 1,
                'false-value': 0,
            },
        }),
        ticketId: new Field({
            name: 'ID_SOLICITACAO',
            component: 'ATextField',
            parser: (value) => (value !== '' ? value : null),
            props: {
                label: i18n.t('TICKET_ID'),
                clearable: true,
            },
        }),
        countyId: new Field({
            name: 'ID_MUNICIPIO',
            component: 'ASelect',
            props: {
                label: i18n.t('COUNTY'),
                entity: $select.city,
                clearable: true,
            },
            dependsOn: [
                {
                    fieldName: 'IDS_PARQUE_SERVICO',
                    handler: function () {
                        this.fields.countyId.value = null;
                        this.fields.countyId.props['entity-params'] = { IDS_PARQUE_SERVICO: this.fields.worksitesId.value };
                    },
                },
                {
                    fieldName: 'IDS_PARQUE_SERVICO',
                    handler: function () {
                        this.fields.countyId.props.disabled = !this.fields.worksitesId.value;
                    },
                },
            ],
        }),
        regionId: new Field({
            name: 'ID_REGIAO',
            component: 'ASelect',
            props: {
                label: i18n.t('REGIONS'),
                entity: $select.regions,
                clearable: true,
                'reload-provider': true,
            },
            dependsOn: [
                {
                    fieldName: ['IDS_PARQUE_SERVICO', 'ID_MUNICIPIO'],
                    handler: function () {
                        this.fields.regionId.value = null;
                        this.fields.regionId.props['entity-params'] = {
                            IDS_PARQUE_SERVICO: this.fields.worksitesId.value,
                            ID_MUNICIPIO: this.fields.countyId.value,
                            SOMENTE_ATIVOS: 1,
                        };
                    },
                },
                {
                    fieldName: 'IDS_PARQUE_SERVICO',
                    handler: function () {
                        this.fields.regionId.props.disabled = !this.fields.worksitesId.value;
                    },
                },
            ],
        }),
        publicPlace: new Field({
            name: 'LOGRADOURO',
            component: 'ACombobox',
            parser: (value) => (value ? (value.NOME_LOGRADOURO_COMPLETO ?? value) : null),
            props: {
                label: i18n.t('PUBLIC_PLACE'),
                entity: $autocomplete.publicPlace,
                clearable: true,
                'reload-provider': true,
                'item-text': (item) => (item ? item.NOME_LOGRADOURO_COMPLETO : null),
                '_disabled-clear-not-found': true,
                _minToSearch: 2,
            },
            dependsOn: [
                {
                    fieldName: 'ID_BAIRRO',
                    handler: function () {
                        this.fields.publicPlace.props['entity-params'] = { ID_BAIRRO: this.fields.districtId.value };
                    },
                },
            ],
        }),
        publicPlaceId: new Field({
            name: 'ID_LOGRADOURO',
            dependsOn: [
                {
                    fieldName: 'LOGRADOURO',
                    handler: function () {
                        this.fields.publicPlaceId.value = this.fields.publicPlace.value?.ID_LOGRADOURO ?? null;
                    },
                },
            ],
        }),
        searchComplainer: new Field({
            name: 'BUSCA_RECLAMANTE',
            component: 'ATextField',
            props: {
                label: i18n.t('REQUESTOR'),
                clearable: true,
            },
        }),
        contributorId: new Field({
            name: 'ID_COLABORADOR',
            component: 'AAutocomplete',
            props: {
                label: i18n.t('CLERK'),
                entity: $autocomplete.contributor,
                clearable: true,
            },
        }),
        ticketTypeId: new Field({
            name: 'ID_TIPO_SOLICITACAO',
            component: 'ASelect',
            parser: (value) => (value && Array.isArray(value) && value.length > 0 ? JSON.stringify(value) : null),
            props: {
                label: i18n.t('TICKET_TYPE'),
                entity: $select.issueType,
                'entity-params': {
                    SOMENTE_PREVENTIVAS: 0,
                    SOMENTE_ATRASADAS: 0,
                    HABILITADO_PARQUE: null,
                    HABILITADO_CALLCENTER: null,
                    SOMENTE_NAO_PREVENTIVAS: null,
                },
                multiple: true,
                clearable: true,
            },
            dependsOn: [
                {
                    fieldName: 'IDS_PARQUE_SERVICO',
                    handler: function () {
                        this.fields.ticketTypeId.value = null;
                        this.fields.ticketTypeId.props['entity-params'] = {
                            ...this.fields.ticketTypeId.props['entity-params'],
                            IDS_PARQUE_SERVICO: this.fields.worksitesId.value,
                        };
                        this.fields.ticketTypeId.props.disabled = !this.fields.worksitesId.value;
                    },
                },
            ],
        }),
        ticketStatusId: new Field({
            name: 'ID_STATUS_SOLICITACAO',
            component: 'ASelect',
            props: {
                label: i18n.t('STATUS'),
                entity: $select.ticketStatus,
                clearable: true,
            },
        }),
        hasPhoto: new Field({
            name: 'COM_FOTOS',
            component: 'ASelect',
            value: -1,
            props: {
                label: i18n.t('HAS_PHOTOS'),
                entity: $select.yesNoAll,
                clearable: false,
            },
        }),
        ticketOriginTypeId: new Field({
            name: 'ID_TIPO_ORIGEM_SOLICITACAO',
            component: 'ASelect',
            props: {
                label: i18n.t('TICKET_ORIGIN_TYPE'),
                parser: $util.arrayToString,
                entity: $select.issueOriginType,
                'entity-params': { SEM_RECLAMANTE: 0 },
                clearable: true,
                outlined: true,
                multiple: true,
            },
        }),
        remainingTime: new Field({
            name: ['PRAZO_RESTANTE_MINIMO', 'PRAZO_RESTANTE_MAXIMO', 'GRANDEZA_PRAZO'],
            component: () => import('@components/remainingTimeSlide/RemainingTimeSlide.vue'),
            parser: (value) => (value?.sliderRange && value.sliderRange[0] !== value.sliderRange[1] ? [value.sliderRange[0], value.sliderRange[1], value.remainingTimeType] : []),
            props: {
                _daysLabel: i18n.t('WILL_DELAY_DAYS'),
                _hoursLabel: i18n.t('WILL_DELAY_HOURS'),
            },
        }),
        documentNumber: new Field({
            name: 'NUMERO_DOCUMENTO',
            component: 'ATextField',
            props: {
                label: i18n.t('DOCUMENT_NUMBER'),
                clearable: true,
            },
        }),
        userCompanyId: new Field({
            name: 'ID_EMPRESA_USUARIA',
            component: 'AAutocomplete',
            props: {
                label: i18n.t('USER_COMPANY'),
                entity: $autocomplete.userCompany,
                clearable: true,
            },
        }),
        areaId: new Field({
            name: 'ID_AREA',
            component: 'AAutocomplete',
            props: {
                label: i18n.t('AREA'),
                entity: $autocomplete.area,
                'require-params': ['IDS_PARQUE_SERVICO'],
                clearable: true,
            },
            dependsOn: [
                {
                    fieldName: 'IDS_PARQUE_SERVICO',
                    handler: function () {
                        this.fields.areaId.props['entity-params'] = { IDS_PARQUE_SERVICO: this.fields.worksitesId.value };
                        this.fields.areaId.props.disabled = this.fields.worksitesId.value.length === 0;
                    },
                },
            ],
        }),
        locationGroupId: new Field({
            name: 'ID_AGRUPAMENTO_LOCALIZACAO',
            component: 'ASelect',
            props: {
                label: i18n.t('ALLOWED_LOCATION'),
                entity: $select.groupingLocation,
                clearable: true,
            },
            dependsOn: [
                {
                    fieldName: 'ID_EMPRESA_USUARIA',
                    handler: function () {
                        this.fields.locationGroupId.props['entity-params'] = { ID_EMPRESA_USUARIA: this.fields.userCompanyId.value };
                    },
                },
            ],
        }),
        inconsistent: new Field({
            name: 'INCONSISTENCIA',
            component: 'ASelect',
            parser: (value) => (value != null ? value : -1),
            props: {
                label: i18n.t('INCONSISTENT'),
                entity: $select.yesNo,
                clearable: true,
            },
        }),
        approvePending: new Field({
            name: 'PENDENTE_APROVACAO',
            component: 'ASelect',
            parser: (value) => (value != null ? value : -1),
            props: {
                label: i18n.t('APPROVAL_OF_INCONSISTENT_TICKETS'),
                entity: $select.yesNoAll,
                clearable: true,
            },
        }),
        phone: new Field({
            name: 'NUMERO_TELEFONE',
            component: 'ATextFieldPhone',
            props: {
                label: i18n.t('PHONE'),
                clearable: true,
                'default-country': Vue.prototype.$_aura.getUser().countryCode,
                'only-countries': [Vue.prototype.$_aura.getUser().countryCode],
                'show-dial-code': false,
            },
        }),
        searchReopened: new Field({
            name: 'CONSULTAR_REABERTAS',
            component: 'ASwitch',
            props: {
                label: i18n.t('ONLY_REOPENED_SOLUTIONS'),
                'true-value': 1,
                'false-value': 0,
            },
        }),
        onlyLinked: new Field({
            name: 'SOMENTE_VINCULADOS',
            component: 'ASwitch',
            props: {
                label: i18n.t('ONLY_LINKED'),
                'true-value': 1,
                'false-value': 0,
            },
        }),
        attributeFilter: new Field({
            name: 'FILTRO_ATRIBUTOS',
            component: () => import('@components/attribute/AttributeFilter.vue'),
            parser: $util.attributesToString,
            props: {
                _sgi: 1,
                _docs: 0,
                _general: 0,
            },
        }),
        assetId: new Field({
            name: 'ID_PONTO_SERVICO',
        }),
        patrolId: new Field({
            name: 'ID_RONDA',
        }),
        showLinked: new Field({
            name: 'MOSTRAR_VINCULADOS',
        }),
        searchResumedByDate: new Field({
            name: 'CONSULTA_RESUMINDA_POR_DATA',
        }),
        minRemainingTime: new Field({
            name: 'PRAZO_RESTANTE_MINIMO',
        }),
        maxRemainingTime: new Field({
            name: 'PRAZO_RESTANTE_MAXIMO',
        }),
        deadlineType: new Field({
            name: 'GRANDEZA_PRAZO',
        }),
        showIssueType: new Field({
            name: 'EXIBIR_TIPO_OCORRENCIA',
        }),
        issueTypeId: new Field({
            name: 'ID_TIPO_OCORRENCIA',
        }),
        reportSampleId: new Field({
            name: 'ID_AMOSTRA_LAUDO',
        }),
        onlyTotals: new Field({
            name: 'APENAS_TOTAIS',
        }),
        page: new Field({
            name: 'PAGE',
        }),
        pageSize: new Field({
            name: 'PAGE_SIZE',
        }),
        situation: new Field({
            name: 'SITUATION',
        }),
        isIotHub: new Field({
            name: 'IS_IOT_HUB',
        }),
    };
}
export class GetTicketListWithAssetLatLng extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarSolicitacaoComLatitudeLongitudePontosServicos',
            type: 'SEARCH',
            node: 'SOLICITACOES.SOLICITACAO',
            fields: getTicketFields(),
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketList extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarSolicitacao',
            type: 'SEARCH',
            node: 'SOLICITACOES.SOLICITACAO',
            fields: getTicketFields(),
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketListSurveySamples extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarSolicitacao',
            type: 'SEARCH',
            node: 'SOLICITACOES.SOLICITACAO',
            fields: {
                worksitesId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $select.worksites,
                        clearable: true,
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: function () {
                                if (!this.fields.worksitesId.value) {
                                    this.fields.ticketTypeId.value = null;
                                }
                            },
                        },
                    ],
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                search: new Field({
                    name: 'BUSCA',
                    component: 'ATextField',
                    parser: (value) => value || '',
                    props: {
                        label: i18n.t('SEARCH'),
                        clearable: true,
                    },
                }),
                complainDate: new Field({
                    name: 'DATA_RECLAMACAO',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('INITIAL_REQUEST_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': false,
                    },
                }),
                finalDate: new Field({
                    name: 'DATA_FINAL',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('FINAL_REQUEST_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': false,
                    },
                }),
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                    component: 'ATextField',
                    parser: (value) => (value !== '' ? value : null),
                    props: {
                        label: i18n.t('TICKET_ID'),
                        clearable: true,
                    },
                }),
                countyId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('COUNTY'),
                        entity: $select.city,
                        clearable: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: function () {
                                this.fields.countyId.value = null;
                                this.fields.countyId.props['entity-params'] = { IDS_PARQUE_SERVICO: this.fields.worksitesId.value };
                            },
                        },
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: function () {
                                this.fields.countyId.props.disabled = !this.fields.worksitesId.value;
                            },
                        },
                    ],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REGIONS'),
                        entity: $select.regions,
                        clearable: true,
                        'reload-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['IDS_PARQUE_SERVICO', 'ID_MUNICIPIO'],
                            handler: function () {
                                this.fields.regionId.value = null;
                                this.fields.regionId.props['entity-params'] = {
                                    IDS_PARQUE_SERVICO: this.fields.worksitesId.value,
                                    ID_MUNICIPIO: this.fields.countyId.value,
                                    SOMENTE_ATIVOS: 1,
                                };
                            },
                        },
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: function () {
                                this.fields.regionId.props.disabled = !this.fields.worksitesId.value;
                            },
                        },
                    ],
                }),
                publicPlace: new Field({
                    name: 'LOGRADOURO',
                    component: 'ACombobox',
                    parser: (value) => (value ? (value.NOME_LOGRADOURO_COMPLETO ?? value) : null),
                    props: {
                        label: i18n.t('PUBLIC_PLACE'),
                        entity: $autocomplete.publicPlace,
                        clearable: true,
                        'reload-provider': true,
                        'item-text': (item) => (item ? item.NOME_LOGRADOURO_COMPLETO : null),
                        '_disabled-clear-not-found': true,
                        _minToSearch: 2,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_BAIRRO',
                            handler: function () {
                                this.fields.publicPlace.props['entity-params'] = { ID_BAIRRO: this.fields.districtId.value };
                            },
                        },
                    ],
                }),
                publicPlaceId: new Field({
                    name: 'ID_LOGRADOURO',
                    dependsOn: [
                        {
                            fieldName: 'LOGRADOURO',
                            handler: function () {
                                this.fields.publicPlaceId.value = this.fields.publicPlace.value?.ID_LOGRADOURO ?? null;
                            },
                        },
                    ],
                }),
                searchComplainer: new Field({
                    name: 'BUSCA_RECLAMANTE',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('REQUESTOR'),
                        clearable: true,
                    },
                }),
                contributorId: new Field({
                    name: 'ID_COLABORADOR',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CLERK'),
                        entity: $autocomplete.contributor,
                        clearable: true,
                    },
                }),
                issueOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_OCORRENCIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ISSUE_ORIGIN_TYPE'),
                        entity: $select.issueOriginType,
                        clearable: true,
                        multiple: true,
                    },
                }),
                ticketCategoryId: new Field({
                    name: 'ID_CATEGORIA_SOLICITACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TICKET_CATEGORY'),
                        entity: $select.ticketCategory,
                        clearable: true,
                        multiple: true,
                    },
                }),
                maintenanceStatusId: new Field({
                    name: 'ID_STATUS_ATENDIMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MAINTENANCE_STATUS'),
                        entity: $select.maintenanceStatus,
                        clearable: true,
                    },
                }),
                issueTypeIds: new Field({
                    name: 'IDS_TIPO_OCORRENCIA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $select.issueType,
                        'entity-params': { HABILITADO_PARQUE: -1, HABILITADO_CALLCENTER: -1 },
                        clearable: true,
                        multiple: true,
                    },
                }),
                ticketStatusId: new Field({
                    name: 'ID_STATUS_SOLICITACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $select.ticketStatus,
                        clearable: true,
                    },
                }),
                remainingTime: new Field({
                    name: ['PRAZO_RESTANTE_MINIMO', 'PRAZO_RESTANTE_MAXIMO', 'GRANDEZA_PRAZO'],
                    component: () => import('@components/remainingTimeSlide/RemainingTimeSlide.vue'),
                    parser: (value) => (value?.sliderRange && value.sliderRange[0] !== value.sliderRange[1] ? [value.sliderRange[0], value.sliderRange[1], value.remainingTimeType] : []),
                    props: {
                        _daysLabel: i18n.t('WILL_DELAY_DAYS'),
                        _hoursLabel: i18n.t('WILL_DELAY_HOURS'),
                    },
                }),
                protocolNumber: new Field({
                    name: 'NUM_PROTOCOLO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('PROTOCOL'),
                        clearable: true,
                    },
                }),
                documentNumber: new Field({
                    name: 'NUMERO_DOCUMENTO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('DOCUMENT_NUMBER'),
                        clearable: true,
                    },
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('USER_COMPANY'),
                        entity: $autocomplete.userCompany,
                        clearable: true,
                    },
                }),
                areaId: new Field({
                    name: 'ID_AREA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('AREA'),
                        entity: $autocomplete.area,
                        'require-params': ['IDS_PARQUE_SERVICO'],
                        clearable: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: function () {
                                this.fields.areaId.props['entity-params'] = { IDS_PARQUE_SERVICO: this.fields.worksitesId.value };
                                this.fields.areaId.props.disabled = this.fields.worksitesId.value.length === 0;
                            },
                        },
                    ],
                }),
                locationGroupId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALLOWED_LOCATION'),
                        entity: $select.groupingLocation,
                        clearable: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_EMPRESA_USUARIA',
                            handler: function () {
                                this.fields.locationGroupId.props['entity-params'] = { ID_EMPRESA_USUARIA: this.fields.userCompanyId.value };
                            },
                        },
                    ],
                }),
                inconsistent: new Field({
                    name: 'INCONSISTENCIA',
                    component: 'ASelect',
                    parser: (value) => (value != null ? value : -1),
                    props: {
                        label: i18n.t('INCONSISTENT'),
                        entity: $select.yesNo,
                        clearable: true,
                    },
                }),
                phone: new Field({
                    name: 'NUMERO_TELEFONE',
                    component: 'ATextFieldPhone',
                    props: {
                        label: i18n.t('PHONE'),
                        clearable: true,
                        'default-country': Vue.prototype.$_aura.getUser().countryCode,
                        'only-countries': [Vue.prototype.$_aura.getUser().countryCode],
                        'show-dial-code': false,
                    },
                }),
                searchReopened: new Field({
                    name: 'CONSULTAR_REABERTAS',
                    component: 'ASwitch',
                    props: {
                        label: i18n.t('ONLY_REOPENED_SOLUTIONS'),
                        'true-value': 1,
                        'false-value': 0,
                    },
                }),
                onlyWithPhoto: new Field({
                    name: 'APENAS_COM_FOTO',
                    component: 'ASwitch',
                    props: {
                        label: i18n.t('ONLY_WITH_PHOTO'),
                        'true-value': 1,
                        'false-value': 0,
                    },
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _sgi: 1,
                        _docs: 0,
                        _general: 0,
                    },
                }),
                searchResumedByDate: new Field({
                    name: 'CONSULTA_RESUMINDA_POR_DATA',
                }),
                showIssueType: new Field({
                    name: 'EXIBIR_TIPO_OCORRENCIA',
                }),
                onlyTotals: new Field({
                    name: 'APENAS_TOTAIS',
                }),
                page: new Field({
                    name: 'PAGE',
                }),
                pageSize: new Field({
                    name: 'PAGE_SIZE',
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
                surveySample: new Field({
                    name: 'AMOSTRA_PESQUISA',
                }),
                includeVinculatedSamples: new Field({
                    name: 'INCLUIR_AMOSTRAS_VINCULADAS',
                }),
                surveyId: new Field({
                    name: 'ID_PESQUISA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveTicket extends CallCommand {
    constructor() {
        super({
            name: 'SalvarSolicitacao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketStatusId: new Field({
                    name: 'ID_STATUS_SOLICITACAO',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                ticketOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_SOLICITACAO',
                }),
                attendantId: new Field({
                    name: 'ID_ATENDENTE',
                }),
                complainDate: new Field({
                    name: 'DATA_RECLAMACAO',
                }),
                complainTime: new Field({
                    name: 'HORA_RECLAMACAO',
                }),
                protocolNumber: new Field({
                    name: 'NUMERO_PROTOCOLO',
                }),
                identityTypeId: new Field({
                    name: 'ID_TIPO_IDENTIDADE',
                }),
                documentNumber: new Field({
                    name: 'NUMERO_DOCUMENTO',
                }),
                observations: new Field({
                    name: 'OBSERVACOES',
                }),
                complainerId: new Field({
                    name: 'ID_RECLAMANTE',
                }),
                fullName: new Field({
                    name: 'NOME_COMPLETO_RECLAMANTE',
                }),
                complainerIdentityTypeId: new Field({
                    name: 'ID_TIPO_IDENTIDADE_COMPLAINER',
                }),
                cpf: new Field({
                    name: 'CPF_RECLAMANTE',
                }),
                cnpj: new Field({
                    name: 'CNPJ_RECLAMANTE',
                }),
                birthdayDate: new Field({
                    name: 'DATA_NASCIMENTO',
                }),
                ageOfMajorityCheckbox: new Field({
                    name: 'CHECKBOX_MAIORIDADE',
                }),
                dataSaved: new Field({
                    name: 'DADOS_SALVOS',
                }),
                registerOriginId: new Field({
                    name: 'ID_TIPO_ORIGEM_RECLAMANTE',
                }),
                foneType: new Field({
                    name: 'TIPO_TELEFONE',
                }),
                foneSource: new Field({
                    name: 'ORIGEM_TELEFONE',
                }),
                rg: new Field({
                    name: 'RG_RECLAMANTE',
                }),
                fone1: new Field({
                    name: 'FONE1_RECLAMANTE',
                }),
                fone2: new Field({
                    name: 'FONE2_RECLAMANTE',
                }),
                email: new Field({
                    name: 'EMAIL_RECLAMANTE',
                }),
                gender: new Field({
                    name: 'SEXO',
                }),
                complainerCep: new Field({
                    name: 'NUM_CEP_RECLAMANTE',
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO_RECLAMANTE',
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO_RECLAMANTE',
                }),
                address: new Field({
                    name: 'ENDERECO_RECLAMANTE',
                }),
                streetId: new Field({
                    name: 'ID_LOGRADOURO_RECLAMANTE',
                }),
                localNumber: new Field({
                    name: 'NUMERO_LOCAL_RECLAMANTE',
                }),
                localDescription: new Field({
                    name: 'COMPLEMENTO_LOCAL',
                }),
                linkedTicketId: new Field({
                    name: 'ID_SOLICITACAO_VINCULADA',
                }),
                linkedIssueType: new Field({
                    name: 'ID_TIPO_OCORRENCIA_VINCULADA',
                }),
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EndTicketElaboration extends CallCommand {
    constructor() {
        super({
            name: 'FinalizarElaboracaoSolicitacao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                attendenceTime: new Field({
                    name: 'TEMPO_ATENDIMENTO',
                }),
                inclusion: new Field({
                    name: 'INCLUSAO',
                }),
                onlyEnding: new Field({
                    name: 'SOMENTE_FINALIZACAO',
                }),
                autheticationId: new Field({
                    name: 'ID_AUTENTICACAO',
                }),
                group: new Field({
                    name: 'AGRUPAR',
                }),
                linkType: new Field({
                    name: 'TIPO_VINCULACAO',
                }),
                verificationCode: new Field({
                    name: 'CODIGO_VERIFICACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveTicketAlert extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAlertaSolicitacao',
            config: { type: 'auth', customMessage: i18n.t('COMMAND_NEEDS_AUTHENTICATION') },
            type: 'SAVE',
            fields: {
                ticketAlertId: new Field({
                    name: 'ID_ALERTA_SOLICITACAO',
                }),
                ticketAlertType: new Field({
                    name: 'ID_TIPO_ALERTA_SOLICITACAO',
                }),
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
                details: new Field({
                    name: 'DETALHES',
                }),
                issueId: new Field({
                    name: 'ID_OCORRENCIA',
                }),
                issueAssetIndex: new Field({
                    name: 'INDEX_OCORRENCIA_PS',
                }),
                receiveAuthId: new Field({
                    name: 'ID_AUTENT_RECEBIMENTO',
                }),
                ticketAlertAuthId: new Field({
                    name: 'ID_AUTENT_ALERTA_SOLICITACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketDetailsByTicketId extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDetalhesSolicitacao',
            type: 'NONE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetMultipleAssetsPermission extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarMultiplosPontosPermitido',
            type: 'SEARCH',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
            },
        });
    }
    async callCommand(params, config, debug) {
        return super.callCommand(params, config, debug);
    }
}
export class IncludeAssetOnTicket extends CallCommand {
    constructor() {
        super({
            name: 'IncluirPontoServicoSolicitacao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
                ticketTypeId: new Field({
                    name: 'ID_TIPO_SOLICITACAO',
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                }),
                districtName: new Field({
                    name: 'NOME_BAIRRO',
                }),
                cepNumber: new Field({
                    name: 'NUM_CEP',
                }),
                placeId: new Field({
                    name: 'ID_LOGRADOURO',
                }),
                localNumber: new Field({
                    name: 'NUMERO_LOCAL',
                }),
                obs: new Field({
                    name: 'OBS',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                onlyFreeAddress: new Field({
                    name: 'APENAS_ENDERECO_LIVRE',
                }),
                freeAddress: new Field({
                    name: 'ENDERECO_LIVRE',
                }),
                complementaryReference: new Field({
                    name: 'REFERENCIA_COMPLEMENTAR',
                }),
                issueAssetPriorityAbbreviation: new Field({
                    name: 'SIGLA_PRIORIDADE_PONTO_OCORR',
                }),
                latitude: new Field({
                    name: 'LATITUDE',
                }),
                longitude: new Field({
                    name: 'LONGITUDE',
                }),
                onlyMap: new Field({
                    name: 'SOMENTE_MAPA',
                }),
                android: new Field({
                    name: 'ANDROID',
                }),
                linkedIssueId: new Field({
                    name: 'ID_OCORRENCIA_VINCULADA',
                }),
                linkedIssueAssetId: new Field({
                    name: 'INDEX_OCORRENCIA_PS_VINCULADA',
                }),
                ticketAssetCategory: new Field({
                    name: 'ID_CATEGORIA_PONTO_SOLIC',
                }),
                patrolId: new Field({
                    name: 'ID_RONDA',
                }),
                ticketImage: new Field({
                    name: 'IMAGEM_SOLICITACAO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveTicketDefaultAsset extends CallCommand {
    constructor() {
        super({
            name: 'SalvarPontoSolicitacaoPadrao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetCategoryId: new Field({
                    name: 'ID_CATEGORIA_PONTO_SOLICITACAO',
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                }),
                complainerId: new Field({
                    name: 'ID_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetCommandPermission extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPermissaoComando',
            type: 'NONE',
            fields: {
                commandName: new Field({
                    name: 'NOME_COMANDO_VERIFICACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketAssets extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPontosServicoSolicitacao',
            type: 'SEARCH',
            node: 'PONTOS_SERVICOS_SOLICITACAO.PONTO_SERVICO_SOLICITACAO',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
                issueId: new Field({
                    name: 'ID_OCORRENCIA',
                }),
                issueAssetIndex: new Field({
                    name: 'INDEX_OCORRENCIA_PS',
                }),
                patrolId: new Field({
                    name: 'ID_RONDA',
                }),
                assetId: new Field({
                    name: 'ID_PONTO_SERVICO',
                }),
                confirmedTicket: new Field({
                    name: 'SOLICITACAO_CONFIRMADA',
                }),
                sortCurrent: new Field({
                    name: 'ORDENAR_ATUAL',
                }),
                page: new Field({
                    name: 'PAGE',
                }),
                pageSize: new Field({
                    name: 'PAGE_SIZE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class CancelTicketEndingFlowByTicketId extends CallCommand {
    constructor() {
        super({
            name: 'CancelarFluxoEncerramentoSolicitacao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveTicketAssetEndingData extends CallCommand {
    constructor() {
        super({
            name: 'SalvarDadosEncerramentoPontosSolic',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                destinyId: new Field({
                    name: 'ID_DESTINO',
                }),
                reasonId: new Field({
                    name: 'ID_MOTIVO',
                }),
                description: new Field({
                    name: 'DESCRICAO',
                }),
                data: new Field({
                    name: 'DATA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketHistory extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoSolicitacao',
            type: 'SEARCH',
            node: 'HISTORICOS.HISTORICO',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteTicketAsset extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirPontoServicoSolicitacao',
            type: 'DELETE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketAlert extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAlertaSolicitacao',
            type: 'NONE',
            fields: {
                ticketAlertId: new Field({
                    name: 'ID_ALERTA_SOLICITACAO',
                }),
                ticketAlertTypeId: new Field({
                    name: 'ID_TIPO_ALERTA_SOLICITACAO',
                }),
                ticketAlertAuthId: new Field({
                    name: 'ID_AUTENT_ALERTA_SOLICITACAO',
                }),
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
                details: new Field({
                    name: 'DETALHES',
                }),
                receiveAuthId: new Field({
                    name: 'ID_AUTENT_RECEBIMENTO',
                }),
                issueId: new Field({
                    name: 'ID_OCORRENCIA',
                }),
                assetIssueIndex: new Field({
                    name: 'INDEX_OCORRENCIA_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ConfirmAlertReceivement extends CallCommand {
    constructor() {
        super({
            name: 'ConfirmarRecebimentoAlerta',
            type: 'SAVE',
            config: { type: 'auth', customMessage: i18n.t('COMMAND_NEEDS_AUTHENTICATION') },
            fields: {
                ticketAlertId: new Field({
                    name: 'ID_ALERTA_SOLICITACAO',
                }),
                receiveAuthId: new Field({
                    name: 'ID_AUTENT_RECEBIMENTO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketPhotos extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarListaFotosSolicitacao',
            type: 'NONE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetComplainerById extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarReclamante',
            type: 'NONE',
            fields: {
                complainerId: new Field({
                    name: 'ID_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetComplainerDataByFone extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDadosReclamanteByFone',
            type: 'NONE',
            fields: {
                fone1: new Field({
                    name: 'FONE1',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetComplainerDataByCPF extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDadosReclamanteByCPF',
            type: 'NONE',
            fields: {
                complainerCpf: new Field({
                    name: 'CPF_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetComplainerDataByCNPJ extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDadosReclamanteByCNPJ',
            type: 'NONE',
            fields: {
                complainerCnpj: new Field({
                    name: 'CNPJ_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetComplainerDataByRG extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDadosReclamanteByRG',
            type: 'NONE',
            fields: {
                complainerRg: new Field({
                    name: 'RG_RECLAMANTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketStatusByTicketId extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarStatusSolicitacao',
            type: 'NONE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class CancelTicketElaborationByTicketId extends CallCommand {
    constructor() {
        super({
            name: 'CancelarElaboracaoSolicitacao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class UncancelTicketElaborationByTicketId extends CallCommand {
    constructor() {
        super({
            name: 'DescancelarSolicitacao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class CancelTicket extends CallCommand {
    constructor() {
        super({
            name: 'CancelarSolicitacao',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                cancelReasonId: new Field({
                    name: 'ID_MOTIVO_CANCELAMENTO',
                }),
                cancelReason: new Field({
                    name: 'MOTIVO_CANCELAMENTO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketAssetsByComplainer extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPontosSolicitacaoPorReclamante',
            type: 'SEARCH',
            node: 'SOLICITACAO_PONTOS_SERVICO.SOLICITACAO_PONTO_SERVICO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                complainerId: new Field({
                    name: 'ID_RECLAMANTE',
                }),
                name: new Field({
                    name: 'NOME',
                }),
                protocolNumber: new Field({
                    name: 'NUMERO_PROTOCOLO',
                }),
                onlyOpened: new Field({
                    name: 'SOMENTE_ABERTA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAttributesByTicketAsset extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtributosPorSolicitacaoPontoServico',
            type: 'NONE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetAttributesByIssueType extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAtributosPorTipoOcorrencia',
            type: 'NONE',
            fields: {
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class VerifyTicketCatRequirements extends CallCommand {
    constructor() {
        super({
            name: 'VerificarRequisitosCatSolicitacao',
            type: 'NONE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetCategoryId: new Field({
                    name: 'ID_CATEGORIA_PONTO_SOLIC',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteTicket extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirSolicitacao',
            type: 'DELETE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetEmptyTickets extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarSolicitacoesVazias',
            type: 'SEARCH',
            node: 'SOLICITACOES.SOLICITACAO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketAssetsByPlate extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPontosSolicitacaoByPlaqueta',
            type: 'SEARCH',
            node: 'SOLICITACAO_PONTOS_SERVICO.SOLICITACAO_PONTO_SERVICO',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                identificationNumber: new Field({
                    name: 'NUMERO_IDENTIFICACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTotalTicketsByDay extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarTotaisSolicitacoesPorDia',
            type: 'NONE',
            fields: {
                complainerId: new Field({
                    name: 'ID_ATENDENTE',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                complainDate: new Field({
                    name: 'DATA_RECLAMACAO',
                }),
                all: new Field({
                    name: 'TODOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetPendingPurchaseTickets extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarItensSolicCompraPendente',
            type: 'SEARCH',
            node: 'ITENS_SOLIC_COMPRA_PENDENTES.ITEM_SOLIC_COMPRA_PENDENTES',
            fields: {
                stockCenterId: new Field({
                    name: 'ID_CENTRO_ESTOQUE',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STOCK_CENTER'),
                        entity: $select.stockCenter,
                    },
                }),
                keyword: new Field({
                    name: 'PALAVRA_CHAVE',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                    },
                }),
                dateLimit: new Field({
                    name: 'DATA_LIMITE',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('LIMIT_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                dateType: new Field({
                    name: 'TIPO_DATA',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('TICKETS'),
                        entity: $select.pendingTicketDataType,
                    },
                }),
                order: new Field({
                    name: 'ORDENACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ORDINATION'),
                        entity: $select.pendingTicketsOrderBy,
                    },
                }),
                includeMinumumStockTicket: new Field({
                    name: 'INCLUIR_SOLIC_ESTOQUE_MINIMO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MINIMUM_STOCK'),
                        entity: $select.pendingTicketMinimumStock,
                    },
                }),
                quotiationNum: new Field({
                    name: 'NUM_COTACAO',
                }),
                familyCode: new Field({
                    name: 'COD_FAMILIA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('FAMILY'),
                        entity: $autocomplete.families,
                    },
                }),
                purchaseNecessity: new Field({
                    name: 'NECESSIDADE_COMPRA',
                    component: 'ACheckbox',
                    props: {
                        label: i18n.t('ONLY_ITEMS_WITH_PURCHASE_NECESSITY'),
                        falseValue: 0,
                        trueValue: 1,
                    },
                    value: 1,
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveAlertActivityFlow extends CallCommand {
    constructor() {
        super({
            name: 'SalvarFluxoAtividadeAlerta',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
                issueId: new Field({
                    name: 'ID_OCORRENCIA',
                }),
                issueAssetIndex: new Field({
                    name: 'INDEX_OCORRENCIA_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveAutomaticServiceOrder extends CallCommand {
    constructor() {
        super({
            name: 'GerarOrdemServicoAutomatica',
            type: 'SAVE',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetServerDateTime extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDataHoraServidor',
            type: 'SEARCH',
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveTicketWithMultiplesAssets extends CallCommand {
    constructor() {
        super({
            name: 'SalvarSolicitacaoMultiplosPontos',
            type: 'SAVE',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                ticketDate: new Field({
                    name: 'DATA_RECLAMACAO',
                }),
                ticketHour: new Field({
                    name: 'HORA_RECLAMACAO',
                }),
                ticketOriginTypeId: new Field({
                    name: 'ID_TIPO_ORIGEM_SOLICITACAO',
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                }),
                priorityTypeId: new Field({
                    name: 'ID_TIPO_PRIORIDADE',
                }),
                assets: new Field({
                    name: 'PONTOS',
                }),
                observation: new Field({
                    name: 'OBSERVACOES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketAlerts extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAlertasSolicitacao',
            type: 'SEARCH',
            node: 'ALERTAS.ALERTA',
            fields: {
                alertStartDate: new Field({
                    name: 'DATA_INICIO_AS',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_ALERT_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                alertEndDate: new Field({
                    name: 'DATA_CONCLUSAO_AS',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_ALERT_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                protocolNumber: new Field({
                    name: 'NUM_PROTOCOLO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('PROTOCOL'),
                        type: 'string',
                        clearable: true,
                    },
                }),
                ticketStartDate: new Field({
                    name: 'DATA_INICIO_OS',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('INITIAL_TICKET_DATE'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                ticketEndDate: new Field({
                    name: 'DATA_CONCLUSAO_OS',
                    component: 'ADatePicker',
                    props: {
                        label: i18n.t('ENDING_TICKET_DATE'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                searchComplainer: new Field({
                    name: 'NOME_RECLAMANTE',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('COMPLAINER'),
                        type: 'string',
                        clearable: true,
                    },
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $autocomplete.region,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.worksiteId.value;
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                publicPlace: new Field({
                    name: 'LOGRADOURO',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('PUBLIC_PLACE'),
                        type: 'string',
                        clearable: true,
                    },
                }),
                issueTypeId: new Field({
                    name: 'ID_TIPO_OCORRENCIA',
                    component: 'AAutocomplete',
                    parser: $util.arrayToString,
                    props: {
                        label: i18n.t('ISSUE_TYPE'),
                        entity: $autocomplete.issueType,
                        'entity-params': {
                            HABILITADO_PARQUE: null,
                            HABILITADO_CALLCENTER: null,
                            HABILITADO_CI: null,
                        },
                        clearable: true,
                        'clear-provider': true,
                        multiple: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.issueTypeId.props.disabled = !fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                requestStatusId: new Field({
                    name: 'ID_STATUS_SOLICITACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $select.ticketStatus,
                        clearable: true,
                    },
                }),
            },
        });
    }
    async callCommand(param, debug, config) {
        return super.callCommand(param, debug, config);
    }
}
export class SaveAttributeIssueAsset extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAtributoOcorrenciaPontoServico',
            type: 'SAVE',
            fields: {
                issueId: new Field({
                    name: 'ID_OCORRENCIA',
                }),
                issueAssetIndex: new Field({
                    name: 'INDEX_OCORRENCIA_PS',
                }),
                attributeSchemeId: new Field({
                    name: 'ID_ESQUEMA_ATRIBUTOS',
                }),
                attributeId: new Field({
                    name: 'ID_ATRIBUTO',
                }),
                value: new Field({
                    name: 'VALOR',
                }),
                valueAttributeId: new Field({
                    name: 'ID_ATRIBUTO_VALOR',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetTicketDeadlineHistory extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoJanelasSolicitacao',
            type: 'SEARCH',
            node: 'HISTORICO_JANELAS_SOLICITACAO.JANELAS.JANELA',
            fields: {
                ticketId: new Field({
                    name: 'ID_SOLICITACAO',
                }),
                ticketAssetIndex: new Field({
                    name: 'INDEX_SOLICITACAO_PS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetComplainerByAttributes extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarReclamantePorAtributos',
            type: 'SEARCH',
            fields: {
                attributesJson: new Field({
                    name: 'ATRIBUTOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
