<template>
    <div>
        <a-badge
            class='d-flex'
            overlap
            :content='selectCount'
            :value='!!selectCount && multiple'>
            <a-select
                v-model='valueLazy'
                :entity='null'
                :loading='loading'
                v-bind='$attrs'
                :items='dataProvider'
                :item-value='$attrs["item-value"] ? $attrs["item-value"] : entity.ID_FIELD_NAME'
                :item-text='$attrs["item-text"] ? $attrs["item-text"] : entity.DESCRIPTION_FIELD_NAME'
                :search-input.sync='search'
                :value-comparator='compareItems'
                v-on='$listeners'
                :menu-props='menuProps'
                @click='openDialog'>
                <!-- eslint-disable-next-line -->
                <slot v-for='slot in Object.keys($slots)' v-if='slot !== "prepend-inner"' :slot='slot' :name='slot' />
                <!-- eslint-disable-next-line -->
                <template v-for='slot in Object.keys($scopedSlots)' v-if='slot !== "prepend-inner"' :slot='slot' slot-scope='scope'>
                    <slot :name='slot' v-bind='scope' />
                </template>
                <template v-slot:prepend-inner>
                    <slot name='prepend-inner' />
                    <div v-if='entity.EDIT_DIALOG && !$_aura.isEmpty(entity.EDIT_DIALOG.page)'>
                        <a-icon :color='valueLazy && !multiple ? "success" : "primary"' @click='openEditDialog($editDialog)'>
                            {{valueLazy && !multiple ? 'fa-edit' : 'fa-plus'}}
                        </a-icon>
                    </div>
                    <div v-else-if='edit'>
                        <a-icon :color='valueLazy && !multiple ? "success" : "primary"' @click='$emit("edit", { reload: refreshProvider })'>
                            {{valueLazy && !multiple ? 'fa-edit' : 'fa-plus'}}
                        </a-icon>
                    </div>
                </template>
                <template v-slot:append>
                    <slot name='append' />
                </template>
                <template v-slot:append-outer>
                    <slot name='append-outer' />
                </template>
                <template v-if='multiple' v-slot:selection='{ item, index }'>
                    <span v-if='index === 0'>{{ item[entity.DESCRIPTION_FIELD_NAME] }}</span>
                    <span
                        v-if='value.length > 1 && index === 1'
                    >
                        , ...
                    </span>
                </template>
            </a-select>
        </a-badge>

        <a-dialog
            v-model='dialog'
            max-width='600'
        >
            <a-card>
                </a-card-actions>
                <a-card-title>
                    {{ $attrs.label }}
                    <a-spacer/>
                    <a-icon
                        close
                        color='mono-grey-50'
                        :small='false'
                        @click='closeDialog'
                    />
                </a-card-title>
                <a-card-text>
                    <a-row>
                        <a-col>
                            <a-data-table
                                :headers='headers'
                                :items='dataProvider'
                                _hideHeader
                            >

                                <template v-slot:[`header.CHECKBOX`]='{ props }'>
                                    <a-simple-checkbox
                                        :value='isAllSelected'
                                        @click='toggleIsAllSelected'
                                    />
                                </template>

                                <template v-slot:item.CHECKBOX='{ item }'>
                                    <a-simple-checkbox
                                        :value='isItemSelected(item)'
                                        @click='toggleSelection(item)'
                                    />
                                </template>
                            </a-data-table>
                        </a-col>
                    </a-row>
                </a-card-text>
                <a-card-actions v-if='$attrs.clearable'>
                    <a-row>
                        <a-col>
                            <a-btn
                                color='delete'
                                @click='clearSelection'
                            >
                                {{ $t('CLEAR') }}
                            </a-btn>
                        </a-col>
                        <a-spacer></a-spacer>
                        <a-col cols='auto'>
                            <a-btn
                                color='primary'
                                @click='closeDialog'
                                >
                                {{ $t('APPLY') }}
                            </a-btn>
                        </a-col>
                    </a-row>
                </a-card-actions>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
    import searchDataProvider from '../../util/mixins/search-data-provider';
    import ASpacer from '../AGrid/ASpacer.vue';

    export default {
        components: { ASpacer },
        name: 'ASelectCustom',
        inheritAttrs: false,
        mixins: [searchDataProvider],
        data: function () {
            return {
                dialog: false,
                headers: [
                    {
                        text: '',
                        value: 'CHECKBOX',
                        width: 1,
                        sortable: false,
                    },
                    {
                        text: this.$attrs.label,
                        value: this.$attrs["item-text"] ? this.$attrs["item-text"] : this.entity.DESCRIPTION_FIELD_NAME,
                        sortable: false,
                    }
                ],
            };
        },
        computed: {
            selectCount: function() {
                if(!this.value){
                    return '';
                } else if (Array.isArray(this.value) && this.value.length > 1){
                    return `+${this.value.length - 1}`;
                }
            },
            isAllSelected: function () {
                if (this.hasMultiSelectedItems) {
                    const anyNotSelected = this.dataProvider.some((item) => {
                        return !this.isItemSelected(item);
                    });
                    return !anyNotSelected;
                }
                return false;
            },
            hasMultiSelectedItems: function () {
                return this.value && this.value.length > 0;
            },
            allowDeselection: function () {
                return this.$attrs.clearable || this.value.length > 1;
            },
            useSelectionDialog: function () {
                return this.multiple && this.dataProvider.length > 5 && !this.$attrs['return-object'] && this.$attrs['return-object'] != '';
            },
            menuProps: function () {
                return {
                    disabled: this.useSelectionDialog
                };
            }
        },
        methods: {
            openDialog: function (event) {
                if (this.useSelectionDialog) {
                    this.menuProps.disabled = true;
                    this.dialog = true;
                }

                if (this.$listeners && this.$listeners.click) {
                    this.$listeners.click(event);
                }
            },
            closeDialog: function () {
                this.dialog = false;
            },
            isItemSelected: function (item) {
                return !!this.value &&
                    (this.$attrs['return-object'] ? this.value.some(val => _.isEqual(val, item)) : this.value.includes(item[this.itemKey]));
            },
            toggleSelection: function (item) {
                const valueToReturn = this.$attrs['return-object'] ? item : item[this.itemKey];
                if (!this.value) {
                    this.$emit('input', [valueToReturn]);
                } else if (this.isItemSelected(item) && this.allowDeselection) {
                    const index = this.$attrs['return-object'] ? _.findIndex(this.value, valueToReturn) : this.value.indexOf(valueToReturn);

                    if (index > -1) {
                        const selectedItems = this.value.slice();
                        selectedItems.splice(index, 1);
                        this.$emit('input', selectedItems);
                    }
                    
                }  else if (!this.value.includes(valueToReturn)) {
                    this.$emit('input', [...this.value, valueToReturn]);
                }
            },
            toggleIsAllSelected: function () {
                if (this.isAllSelected && this.$attrs.clearable) {
                    this.clearSelection();
                } else {
                    this.selectAll();
                }
            },
            clearSelection: function () {
                this.$emit('input', []);
            },
            selectAll: function () {
                if (this.$attrs['return-object'] || this.$attrs['return-object'] === '') {
                    this.$emit('input', _.cloneDeep(this.dataProvider));
                } else {
                    this.$emit('input', this.dataProvider.map((item) => item[this.itemKey]));
                }
            },
        },
    };
</script>
